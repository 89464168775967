import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import Select from 'react-select';
import { customSelectStylesOptions } from './helper';

import { fetchEvents } from '../../store/actions/events';
import { updatePath } from './actions';

const OverlayDropdown = () => {
  const dispatch = useDispatch();
  const overlaysArr = useSelector(({ events: { template: { overlays = [] } } }) => overlays);
  const options = useMemo(() => overlaysArr
    .filter(({ disabled }) => disabled === false)
    .map(({ storageKey, urlMediumSize }) => ({
      label: storageKey.substring(storageKey.length - 12),
      value: urlMediumSize,
    })), [overlaysArr]);

  useEffect(() => {
    dispatch(fetchEvents(dispatch));
  }, []);

  return (
    <Select
      menuPlacement="top"
      placeholder="Overlay"
      options={options}
      styles={customSelectStylesOptions}
      onChange={(selected) => dispatch(updatePath('overlayURL', get(selected, 'value', '')))}
      isClearable
    />
  );
};

export default OverlayDropdown;
