import {
  SET_GRAPHICS_SECTION,
  SET_ALL_GRAPHICS,
  SET_GRAPHICS_SELECTED_IND,
  SET_MULTISELECT_VALUE,
  ADD_GRAPHICS_ITEM,
  DELETE_GRAPHICS_ITEM,
  SET_GRAPHICS_ERROR,
  UPLOAD_GRAPHICS_FILE,
  UPLOAD_GRAPHICS_FILE_DONE,
} from './types';
import arenaImage from '../../../assets/srsIcons/arena-find-me.svg';
import stadiumImage from '../../../assets/srsIcons/stadium-find-me.svg';

const initialState = {
  selectedInd: 0,
  sections: [
    {
      key: 'frontPage',
      label: 'Front Image',
      fileFormat: '.webp',
      error: null,
      isUploading: false,
      file: null,
      validationGuideline: 'At least 1280x720px (9:16 aspect ratio), WebP, and up to 180 KB.',
      data: {},
    },
    {
      key: 'logo',
      label: 'Email Header',
      fileFormat: ['.jpeg', '.png'],
      error: null,
      isUploading: false,
      file: null,
      validationGuideline: 'At least 600x300px (2:1 or 3:2 aspect ratio preferable), JPEG or PNG, and up to 150 KB.',
      data: {},
    },
    {
      key: 'slideshowBanner',
      label: 'Slideshow Banner',
      fileFormat: ['.jpeg', '.png'],
      error: null,
      isUploading: false,
      file: null,
      validationGuideline: 'Image should be 1920x180px, JPEG or PNG, and up to 150 KB.',
      data: {},
    },
    {
      key: 'watermark',
      label: 'Watermark',
      fileFormat: '.png',
      error: null,
      isUploading: false,
      file: null,
      validationGuideline: 'Be 500x500px, PNG, and up to 50 KB.',
      data: {},
    },
    {
      key: 'shareImage',
      label: 'SEO Image',
      fileFormat: ['.jpeg', '.png'],
      error: null,
      isUploading: false,
      file: null,
      validationGuideline: 'This image would appear on search results on google or other search engines. Image must be squared',
      data: {},
    },
    {
      key: 'venueType',
      label: 'SRS Icon',
      options: {
        arena: { label: 'Arena', imageSrc: arenaImage },
        stadium: { label: 'Stadium', imageSrc: stadiumImage },
      },
      value: 'stadium',
    },
  ],
};

export default (state = initialState, { payload = {}, type } = {}) => {
  switch (type) {
  case SET_ALL_GRAPHICS: {
    return {
      ...state,
      sections: state.sections.map((section) => ({
        ...section,
        data: !section.value && Object.fromEntries(
          Object.entries(payload).filter(([entryKey]) => entryKey.startsWith(section.key)),
        ),
        value: section.value && payload[section.key],
      })),
    };
  }

  case SET_GRAPHICS_SELECTED_IND: {
    const { selectedInd } = payload;

    return {
      ...state,
      selectedInd,
    };
  }

  case SET_GRAPHICS_SECTION: {
    return {
      ...state,
      sections: [
        ...state.sections.slice(0, state.selectedInd),
        payload,
        ...state.sections.slice(state.selectedInd + 1),
      ],
    };
  }

  case SET_MULTISELECT_VALUE: {
    const { value } = payload;

    return {
      ...state,
      sections: [
        ...state.sections.slice(0, state.selectedInd),
        { ...state.sections[state.selectedInd], value },
        ...state.sections.slice(state.selectedInd + 1),
      ],
    };
  }

  case DELETE_GRAPHICS_ITEM: {
    const updatedItem = { ...state.sections[state.selectedInd] };

    if (updatedItem.data) {
      updatedItem.error = null;
      updatedItem.file = null;
      Object.keys(updatedItem.data).forEach((prop) => {
        updatedItem.data[prop] = null;
      });
    } else {
      updatedItem.value = null;
    }

    return {
      ...state,
      sections: [
        ...state.sections.slice(0, state.selectedInd),
        updatedItem,
        ...state.sections.slice(state.selectedInd + 1),
      ],
    };
  }

  case ADD_GRAPHICS_ITEM: {
    const { file = null, data } = payload;

    return {
      ...state,
      sections: [
        ...state.sections.slice(0, state.selectedInd),
        {
          ...state.sections[state.selectedInd],
          file,
          data: data || state.sections[state.selectedInd].data,
          isUploading: file && !data,
        },
        ...state.sections.slice(state.selectedInd + 1),
      ],
    };
  }

  case SET_GRAPHICS_ERROR: {
    const { error } = payload;

    return {
      ...state,
      sections: [
        ...state.sections.slice(0, state.selectedInd),
        {
          ...state.sections[state.selectedInd],
          error,
          file: null,
          isUploading: false,
        },
        ...state.sections.slice(state.selectedInd + 1),
      ],
    };
  }

  case UPLOAD_GRAPHICS_FILE: {
    return {
      ...state,
      sections: [
        ...state.sections.slice(0, state.selectedInd),
        {
          ...state.sections[state.selectedInd],
          isUploading: true,
        },
        ...state.sections.slice(state.selectedInd + 1),
      ],
    };
  }

  case UPLOAD_GRAPHICS_FILE_DONE: {
    return {
      ...state,
      sections: [
        ...state.sections.slice(0, state.selectedInd),
        {
          ...state.sections[state.selectedInd],
          isUploading: false,
        },
        ...state.sections.slice(state.selectedInd + 1),
      ],
    };
  }

  default: {
    return state;
  }
  }
};
