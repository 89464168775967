import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Spinner, Button } from 'react-bootstrap';

import imgPlaceholder from '../../../../../assets/images/image-placeholder.svg';


import { uploadFile, setError } from '../../actions';

import style from './style.module.scss';

const FRONT_IMAGE_MIN_WIDTH = 720;
const FRONT_IMAGE_MIN_HEIGHT = 1280;
const BANNER_MIN_WIDTH = 600;
const BANNER_MIN_HEIGHT = 300;
const SLIDESHOW_BANNER_WIDTH = 1920;
const SLIDESHOW_BANNER_HEIGHT = 180;
const WATERMARK_MIN_WIDTH = 1080;
const WATERMARK_MAX_WIDTH = 2160;

const MAX_FRONT_IMAGE_SIZE = 180000;
const MAX_LOGO_SIZE = 150000;
const MAX_WATERMARK_SIZE = 150000;

const ImageUploader = ({
  section: {
    key,
    data,
    file,
    label,
    validationGuideline,
    isUploading,
  },
  dropZoneProps,
  className,
  setErrorAction,
  uploadFileAction,
}) => {
  const isEmpty = Object.values(data).every((val) => !val);
  const imageRef = useRef(null);

  // eslint-disable-next-line consistent-return
  const validateImage = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      const { size } = file.inputFile;

      if (key === 'watermark') {
        if (naturalWidth !== naturalHeight) {
          return setErrorAction('Watermark image should be squared');
        }

        if (naturalWidth < WATERMARK_MIN_WIDTH || naturalWidth > WATERMARK_MAX_WIDTH) {
          return setErrorAction(`Watermark image dimensions should be between ${WATERMARK_MIN_WIDTH} and ${WATERMARK_MAX_WIDTH}`);
        }

        if (size > MAX_WATERMARK_SIZE) {
          return setErrorAction('Watermark image is too large');
        }
      }

      if (key === 'frontPage') {
        if (naturalWidth < FRONT_IMAGE_MIN_WIDTH || naturalHeight < FRONT_IMAGE_MIN_HEIGHT) {
          return setErrorAction(`Front Page image should be least ${FRONT_IMAGE_MIN_WIDTH}x${FRONT_IMAGE_MIN_HEIGHT}px`);
        }

        if (size > MAX_FRONT_IMAGE_SIZE) {
          return setErrorAction('Front Page image is too large');
        }

        if (naturalHeight / naturalWidth < 16 / 9) {
          return setErrorAction('Front Page image aspect ratio should be at least 9x16');
        }
      }

      if (key === 'logo') {
        if (naturalWidth < BANNER_MIN_WIDTH || naturalHeight < BANNER_MIN_HEIGHT) {
          return setErrorAction(`Email Header image should be least ${BANNER_MIN_WIDTH}x${BANNER_MIN_HEIGHT}px`);
        }

        if (size > MAX_LOGO_SIZE) {
          return setErrorAction('Email Header image is too large');
        }

        if (naturalWidth / naturalHeight < 16 / 9) {
          return setErrorAction('Email Header image aspect ratio should be at least 16x9');
        }
      }

      if (key === 'slideshowBanner') {
        if (naturalWidth !== SLIDESHOW_BANNER_WIDTH || naturalHeight !== SLIDESHOW_BANNER_HEIGHT) {
          return setErrorAction(`Image should be ${SLIDESHOW_BANNER_WIDTH}x${SLIDESHOW_BANNER_HEIGHT}px`);
        }
      }

      uploadFileAction(file.inputFile);
    }
  };

  return (
    <>
      {(isEmpty || isUploading) && (
        // eslint-disable-next-line
        <div {...dropZoneProps} className={className}>
          {isUploading && (
            <div className={style.placeholderSpinner}>
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          <img className={style.placeholder} src={imgPlaceholder} alt="Placeholder" />
          <h4 className="mt-4">Drag and drop your file or upload your image.</h4>
          <p className={style.guideline}>{validationGuideline}</p>
          <Button className="small" variant="outline-primary">Upload</Button>
        </div>
      )}

      {!isEmpty && !file && <img src={data[`${key}Url`]} alt={label} />}

      {file && (
        <img
          ref={imageRef}
          onLoad={validateImage}
          className={style.preview}
          src={file.preview}
          alt={label}
        />
      )}
    </>
  );
};

export default connect(
  ({ graphics: { selectedInd, sections } }) => ({ section: sections[selectedInd] }),
  {
    setErrorAction: setError,
    uploadFileAction: uploadFile,
  },
)(ImageUploader);
