import React, { useEffect, useState } from 'react';
import * as Recharts from 'recharts';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import ChartCard from '../../ChartComponents/ChartCard';
import ChartTooltip from '../../ChartComponents/ChartTooltip';
import Info from '../../../../components/Info';

import { formatDateToTimezone } from '../../../../helpers/utils';

const GroupMemoryChart = ({ groupMemoryData, timezone }) => {
  const [chartData, setChartData] = useState([]);
  const [showUsers, setShowUsers] = useState(true);
  const [showSnapshots, setShowSnapshots] = useState(true);
  const [showShares, setShowShares] = useState(true);

  useEffect(() => {
    if (!isEmpty(groupMemoryData)) {
      setChartData(groupMemoryData.map(({
        date, users: photographers, snapshots: photos, shares,
      }) => ({
        photographers,
        photos,
        time: formatDateToTimezone(date, timezone, 'hh:mm a'),
        shares,
      })));
    }
  }, [groupMemoryData]);

  const handleLegendClick = (series) => {
    if (series === 'photos') {
      setShowSnapshots(!showSnapshots);
    } else if (series === 'shares') {
      setShowShares(!showShares);
    } else {
      setShowUsers(!showUsers);
    }
  };

  if (!isEmpty(chartData)) {
    return (
      <ChartCard title={(
        <Info
          label="Group Memory Timeline"
          content="A visualization of the number of photographers, photos, and shares during the event"
          className="ml-2"
        />
      )}
      >
        <Recharts.ResponsiveContainer width="100%" height={350}>
          <Recharts.LineChart data={chartData} isAnimationActive={false}>
            <Recharts.XAxis
              dataKey="time"
              tickLine={false}
              dy={10}
              dx={5}
              tick={{
                fontSize: '12px',
                color: '#B7B8BA',
                marginRight: '10px',
                lineHeight: '27px',
                fontWeigth: '500',
              }}
            />
            <Recharts.YAxis
              tickLine={false}
              dy={-5}
              dx={-5}
              tick={{
                fontSize: '12px',
                color: '#B7B8BA',
                marginRight: '10px',
              }}
            />
            <Recharts.Tooltip content={<ChartTooltip />} />
            <Recharts.Legend
              verticalAlign="top"
              align="right"
              wrapperStyle={{ fontSize: '14px', color: '#4E5155', textTransform: 'capitalize' }}
              iconType="circle"
              iconSize="11"
              onClick={({ dataKey }) => handleLegendClick(dataKey)}
            />
            <Recharts.Line type="monotone" dataKey="photos" stroke="var(--theme-purple)" fill="var(--theme-purple)" dot={false} strokeWidth={4} hide={!showSnapshots} />
            <Recharts.Line type="monotone" dataKey="photographers" stroke="var(--theme-yellow-dark)" fill="var(--theme-yellow-dark)" dot={false} strokeWidth={4} hide={!showUsers} />
            <Recharts.Line type="monotone" dataKey="shares" stroke="var(--theme-red)" fill="var(--theme-red)" dot={false} strokeWidth={4} hide={!showShares} />
          </Recharts.LineChart>
        </Recharts.ResponsiveContainer>
      </ChartCard>
    );
  }

  return null;
};

export default connect(
  ({
    events: { currentEvent: { timezone } },
    analytics: { basic: { data: { usersVsSnapshotsTimelines } } },
  }) => ({
    timezone,
    groupMemoryData: usersVsSnapshotsTimelines,
  }),
)(GroupMemoryChart);
